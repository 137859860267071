/* Base styles */
:root {
  --primary: #f97316;
  --primary-hover: #ea580c;
  --primary-foreground: #ffffff;
  --secondary: #f59e0b;
  --background: #1e293b;
  --foreground: #f8fafc;
  --muted: #334155;
  --muted-foreground: #94a3b8;
  --border: #475569;
  --card: #1e293b;
  --card-foreground: #f8fafc;
  --radius: 0.5rem;
  --font-sans: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.light {
  --primary: #f97316;
  --primary-hover: #ea580c;
  --primary-foreground: #ffffff;
  --secondary: #f59e0b;
  --background: #f8fafc;
  --foreground: #0f172a;
  --muted: #e2e8f0;
  --muted-foreground: #64748b;
  --border: #cbd5e1;
  --card: #ffffff;
  --card-foreground: #0f172a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-sans);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

/* App container */
.app {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--foreground);
}

/* Dark mode overlay updated to lighten the bottom */
.app.dark {
  background: linear-gradient(135deg, rgba(15, 23, 42, 0.6) 0%, rgba(22, 78, 99, 0.3) 100%);
}

.app.light {
  background: linear-gradient(135deg, #f8fafc 0%, #e0f2fe 100%);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.1); /* Add a slight white overlay */
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.btn-default {
  background-color: var(--muted);
  color: var(--foreground);
}

.btn-default:hover {
  background-color: var(--muted-foreground);
}

.btn-primary {
  background: linear-gradient(to right, var(--primary), var(--secondary));
  color: var(--primary-foreground);
}

.btn-primary:hover {
  background: linear-gradient(to right, var(--primary-hover), var(--secondary));
}

.btn-outline {
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--foreground);
}

.btn-outline:hover {
  background-color: var(--muted);
}

.full-width {
  width: 100%;
}

/* Badge */
.badge {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}

.badge-default {
  background-color: var(--muted);
  color: var(--muted-foreground);
}

.badge-outline {
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--foreground);
}

.ecosystem-badge {
  border-color: rgba(249, 115, 22, 0.5);
  color: var(--primary);
}

/* Card */
.card {
  background-color: rgba(30, 41, 59, 0.7); /* Add slight transparency to cards */
  border-radius: var(--radius);
  border: 1px solid var(--border);
  overflow: hidden;
  transition: border-color 0.2s ease;
}

.light .card {
  background-color: rgba(255, 255, 255, 0.85); /* Add slight transparency to cards in light mode */
  border-radius: var(--radius);
  border: 1px solid var(--border);
  overflow: hidden;
  transition: border-color 0.2s ease;
}

.card:hover {
  border-color: rgba(249, 115, 22, 0.5);
}

.card-header {
  padding: 1.25rem 1.25rem 0.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.card-description {
  color: var(--muted-foreground);
  font-size: 0.875rem;
}

.card-content {
  padding: 0.5rem 1.25rem 1.25rem;
  color: var(--muted-foreground);
}

.aftermath-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.aftermath-logo img {
  max-width: 100%;
  height: auto;
  max-height: 40px;
}

.nexa-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #000;
  padding: 0.75rem;
  border-radius: var(--radius);
}

.nexa-logo img {
  max-width: 100%;
  height: auto;
  max-height: 40px;
}

.card-footer {
  padding: 1.25rem;
  border-top: 1px solid var(--border);
}

.card-grid {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
}

.two-columns {
  grid-template-columns: 1fr;
}

.three-columns {
  grid-template-columns: 1fr;
}

.four-columns {
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .three-columns {
    grid-template-columns: repeat(3, 1fr);
  }

  .four-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .four-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Navbar */
/* Dark mode navbar overlay lightened */
.navbar {
  backdrop-filter: blur(12px);
  background-color: rgba(30, 41, 59, 0.15);
}

.light .navbar {
  background-color: rgba(248, 250, 252, 0.25);
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo-icon {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--primary);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.logo-icon.small {
  height: 2rem;
  width: 2rem;
  font-size: 0.75rem;
}

.logo-text {
  font-size: 1.25rem;
  font-weight: 700;
  background: linear-gradient(to right, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-links a {
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-links a:hover {
  color: var(--primary);
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--foreground);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.theme-toggle:hover {
  background-color: var(--muted);
}

.mobile-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--foreground);
  cursor: pointer;
}

.mobile-menu {
  border-top: 1px solid var(--border);
}

.mobile-menu .container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.mobile-menu a {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 0;
  transition: color 0.2s ease;
}

.mobile-menu a:hover {
  color: var(--primary);
}

.mobile-menu .buy-button {
  margin-top: 0.5rem;
}

/* Responsive utilities */
.mobile-only {
  display: none;
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}

/* Hero section */
.hero-section {
  position: relative;
  overflow: hidden;
  padding: 5rem 0 8rem;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.hero-text {
  text-align: center;
  max-width: 600px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin: 1rem 0;
  background: linear-gradient(to right, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--muted-foreground);
  margin-bottom: 1.5rem;
}

.hero-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.social-link {
  color: var(--muted-foreground);
  transition: color 0.2s ease;
}

.social-link:hover {
  color: var(--foreground);
}

.hero-image {
  display: flex;
  justify-content: center;
}

.logo-large {
  position: relative;
  height: 16rem;
  width: 16rem;
}

.logo-glow {
  position: absolute;
  inset: 0;
  background-color: var(--primary);
  border-radius: 9999px;
  opacity: 0.2;
  animation: pulse 2s infinite;
}

.logo-inner {
  position: absolute;
  inset: 2rem;
  background-color: var(--primary);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 6rem;
  font-weight: 700;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}

/* Section styles */
.section-header {
  text-align: center;
  max-width: 768px;
  margin: 0 auto 3rem;
}

.section-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 1rem 0;
}

.section-description {
  color: var(--muted-foreground);
  font-size: 1.125rem;
}

/* About section */
.about-section {
  padding: 5rem 0;
  background-color: rgba(30, 41, 59, 0.1); /* Changed from 0.2 to 0.1 */
}

.light .about-section {
  background-color: rgba(226, 232, 240, 0.1); /* Changed from 0.2 to 0.1 */
}

.trading-section {
  padding: 5rem 0;
}

.trading-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.trading-card .card-content {
  flex-grow: 1;
}

.contract-view {
  margin-top: 3rem;
  text-align: center;
}

.contract-view p {
  color: var(--muted-foreground);
  margin-bottom: 1rem;
}

/* Products section */
.products-section {
  padding: 5rem 0;
  background-color: rgba(30, 41, 59, 0.1); /* Changed from 0.2 to 0.1 */
}

.light .products-section {
  background-color: rgba(226, 232, 240, 0.1); /* Changed from 0.2 to 0.1 */
}

.product-card {
  position: relative;
  border-color: rgba(249, 115, 22, 0.2);
}

.card-accent {
  height: 0.5rem;
  background: linear-gradient(to right, var(--primary), var(--secondary));
}

.product-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.product-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  background-color: rgba(249, 115, 22, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}

.feature-list {
  list-style: none;
  margin-bottom: 1.5rem;
}

.feature-list li {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.check-icon {
  color: var(--primary);
  font-weight: bold;
  flex-shrink: 0;
}

.product-footer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .product-footer {
    flex-direction: row;
  }
}

.powered-section {
  padding: 3rem 0;
  border-top: 1px solid var(--border);
}

.powered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.powered-text {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.powered-text p {
  color: var(--muted-foreground);
}

.powered-link {
  font-size: 1.125rem;
  font-weight: 600;
  transition: color 0.2s ease;
}

.powered-link:hover {
  color: var(--primary);
}

/* Footer */
.footer {
  padding: 2rem 0;
  border-top: 1px solid var(--border);
  background-color: rgba(30, 41, 59, 0.08); /* Changed from 0.15 to 0.08 */
}

.light .footer {
  background-color: rgba(226, 232, 240, 0.08); /* Changed from 0.15 to 0.08 */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-brand {
  font-weight: 600;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-copyright {
  font-size: 0.875rem;
  color: var(--muted-foreground);
}

/* Icon utilities */
.icon-left {
  margin-right: 0.5rem;
}

/* Coming Soon */
.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 600;
  color: var(--primary);
  padding: 0.75rem;
  border: 1px dashed var(--primary);
  border-radius: var(--radius);
  background-color: rgba(249, 115, 22, 0.1);
}

.coming-soon-icon {
  animation: pulse 2s infinite;
}

/* Media queries */
@media (min-width: 768px) {
  .hero-content {
    flex-direction: row;
    text-align: left;
    gap: 4rem;
  }

  .hero-text {
    text-align: left;
  }

  .hero-buttons {
    flex-direction: row;
  }

  .social-links {
    justify-content: flex-start;
  }

  .powered-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Make sure the dark mode text is more visible */
.dark {
  --foreground: #ffffff;
  --muted-foreground: #cbd5e1;
}
