/* Animated Background */
@keyframes auroraAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* General Body Styling */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: linear-gradient(-45deg, 
    #1a2a6c, #b21f1f, #fdbb2d, #22c1c3, #1a2a6c, 
    #ff00cc, #333399, #00ff87, #1a2a6c);
  background-size: 1000% 1000%;
  background-attachment: fixed;
  animation: auroraAnimation 30s ease infinite;
}

body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
  pointer-events: none;
  transition: opacity 0.5s ease;
}

/* Main App Container */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  transition: all 0.3s ease;
  padding: 40px;
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 25px;
  background-color: rgba(40, 44, 52, 0.7);
  backdrop-filter: blur(15px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  position: relative;
  z-index: 1;
}

.App.light {
  background-color: rgba(240, 240, 240, 0.8);
  color: #333;
}

.App.dark {
  background-color: rgba(40, 44, 52, 0.8);
  color: #f0f0f0;
}

/* Logo Styling */
.App-logo {
  height: 25vmin;
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

/* Header Section Styling */
.App-header {
  padding: 20px;
  border-radius: 25px;
  max-width: 800px;
  width: 100%;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: inherit;
  transition: transform 0.2s ease;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

/* Content Card Styling */
.content-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styling for Titles */
h1, h2, h3 {
  margin: 10px 0;
}

/* Paragraph Styling */
p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 10px 0;
}

/* Links Container */
.top-links, .social-links, .contract-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 20px 0;
}

/* Link Styling */
.App-link {
  background: linear-gradient(90deg, #9333EA, #EC4899);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 12px;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0.5rem;
  min-width: 200px;
}

.App-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(236, 72, 153, 0.3);
}

.App-link svg, .App-link img {
  margin-right: 0.5rem;
}

.primary-link {
  background: linear-gradient(90deg, #EC4899, #8B5CF6);
}

/* Scroll Reveal Styles */
.scroll-reveal-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  width: 100%;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.reveal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem; /* Adjust spacing */
  width: 100%;
  max-width: 1200px; /* Consistent width */
  margin: 0 auto; /* Center the container */
}

.reveal-box {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  width: 90%; /* Make boxes responsive */
  max-width: 500px; /* Restrict max width */
  text-align: center;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 auto; /* Center each box */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reveal-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px 0 rgba(31, 38, 135, 0.45);
}

.reveal-box h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ff9800;
}

.reveal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
}

.reveal-button {
  background: linear-gradient(90deg, #9333EA, #EC4899);
  color: white;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  max-width: 300px;
}

.reveal-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(236, 72, 153, 0.3);
}

/* Aftermath Logo Styling */
.aftermath-logo {
  max-width: 150px; /* Restrict maximum width */
  width: 100%;
  height: auto;
  display: block;
  margin: 10px auto;
  transition: transform 0.3s ease;
}

.aftermath-logo:hover {
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .App {
    padding: 20px;
    margin: 10px;
  }

  .reveal-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .reveal-box {
    padding: 1.5rem;
    width: 100%; /* Ensure full width */
    max-width: 90%; /* Prevent overflow */
  }

  .reveal-button {
    max-width: 250px;
    font-size: 1rem;
    padding: 0.8rem 1.2rem;
  }

  h1, h2 {
    font-size: 1.8rem;
  }

  .App-logo {
    height: 20vmin;
  }

  .aftermath-logo {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .reveal-box {
    padding: 1rem;
  }

  .reveal-button {
    max-width: 200px;
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }

  .aftermath-logo {
    max-width: 100px;
  }
}
